<template>
<div id="farmersamples">
  <!-- week lists -->  
    <div :class="'d-flex flex-column ' + (isMobile ? '' : 'sample-container')">
      <title-pond></title-pond>
      <template v-if="isMinnowtech">
        <div class="mt-5 mb-0 pb-0">
          <pond-menu></pond-menu>
        </div>
      </template>
      <v-row style="flex: 0 0 50px; font-size: 13px;">
          <v-col :cols="12" class="d-flex pa-0 align-center">
            <v-btn small 
              color="blue darken-4"
              class="flex-grow-1 pa-8 my-6 updateresult"
              @click="updateResults()"
            >
              Update New Result !!!
            </v-btn>
          </v-col>
      </v-row>
      <!-- Farmer Side -->
      <v-row class="flex-grow-1 flex-shrink-1 scroll full-height">
        <v-simple-table class="mt-0">
          <template #default>
            <thead>
              <tr>
                <!-- <th class="border-right text-center">No.</th> -->
                <th class="border-right text-center">Week of Culture</th>
                <!-- Minnowtech Side -->
                <template v-if="isMinnowtech">
                  <th class="border-right text-center">Total Shrimp Count</th>
                </template>
                <th class="border-left text-center">Individual Shrimp Weight (g)</th>
                <th class="text-center">Total Pond Biomass (kg)</th>
                <th class="text-center">Total Pond Biomass (lbs)</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="loadingStatus">
                <tr>
                  <td class="nodata" colspan="7" align="center">
                    <v-progress-circular
                      indeterminate
                      color="amber"
                    ></v-progress-circular>                    
                  </td>
                </tr>
              </template>
              <template v-else-if="!isReleased">
                <tr>
                  <td class="nodata" colspan="7" align="center">Shrimp data will be released from Friday of Week 2. </td>
                </tr>
              </template>
              <template v-else-if="!samples_list.length">
                <tr>
                  <td class="nodata" colspan="7" align="center">Results will be updated Soon</td>
                </tr>
              </template>
              <template v-else v-for="(item) in samples_list">
                <template >
                  <tr
                    :key="item.id"
                  >
                    <!-- <td class="text-center">
                        <div>{{i+1}}</div>
                    </td> -->
                    <td class="text-left">
                        <div class="text-center">
                          <span class="woc-date">
                            {{getWeekDate(item.result_date)}}
                          </span>
                          <span class="week">
                            W{{item.woc}}
                          </span>
                        </div>
                    </td>
                    <td class="text-right">
                      <div class="d-flex align-center justify-end">
                        {{item.count | formatComma}}
                      </div>
                    </td>
                    <td class="text-right">
                      <div class="d-flex align-center justify-end">
                          {{item.weight}}
                      </div>
                    </td>
                    <td class="text-right">
                      <router-link to="/farmerreport?type=biomasskg">
                        <div v-if="item.weight" class="shrimp biomass">
                            {{getBiomassKilo(item.count, item.weight) | formatComma}}
                        </div>
                      </router-link>
                    </td>
                    <td class="text-right">
                      <router-link to="/farmerreport?type=biomass">
                        <div v-if="item.weight" class="shrimp biomass">
                            {{getBiomass(item.count, item.weight) | formatComma}}
                        </div>
                      </router-link>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
      <v-row style="flex: 0 0 55px" class="justify-center pt-3">
        <v-pagination
          v-model="page"
          :length="pages"
          @input="handlePageChange"
          circle
        ></v-pagination>
      </v-row>
    </div>
</div>
</template>

<script>
import { mapState } from "vuex";

import tokenAxios from "@/components/tokenAxios";
import TitlePond from "@/components/TitlePond";
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required, decimal } from "vuelidate/lib/validators";
import moment from 'moment'
import PondMenu from './PondMenu'

export default {
  name: 'farmer_report_new',
  computed: {
    ...mapState(["isMobile", "user_info", "isMinnowtech", "pond", "loadingStatus"]), // get user_info and pond from store
    // get Pond's name with WOC
    getPond() {
      const diff = (this.pond && this.completed ? moment(this.completed) : moment()).diff(moment(this.pond.date), 'days');
      const woc = Math.floor( (diff - 1) / 7 ) + 1;

      if (this.pond) return `${this.pond.farm_name}, Pond ${this.pond.pond_id} : ${this.$options.filters.formatDate(this.pond.date)} - ${diff} days (W${woc})`
      else return "";
    },
    // Pond data will be released from WOC 3 Fri 
    isReleased() {
      const diff = moment().diff(moment(this.pond.date), 'days');
      const woc = Math.floor( (diff - 1) / 7 ) + 1;
      this.$debug('isReleased', woc, moment().format('ddd'));
      if (woc < 3 && moment().format('ddd') !== 'Fri' ) return false;
      else return true;
    },
    completed: {
      get () {
        return this.pond.completed
      },
      set (value) {
        this.$store.commit('completed', value)
      }
    },
    harveststarted: {
      get () {
        return this.pond.harveststarted
      },
      set (value) {
        this.$store.commit('harveststarted', value)
      }
    },
    harvested: {
      get () {
        return this.pond.harvested
      },
      set (value) {
        this.$store.commit('harvested', value)
      }
    },
    avgweight: {
      get () {
        return this.pond.avgweight
      },
      set (value) {
        this.$store.commit('avgweight', value)
      }
    },
  },
  mixins: [validationMixin],
  validations: {
    assigned: {},
    pond: { required },
    week: { required },
    size: { required, decimal },
    temperature: { required, decimal },
    oxygen: { required, decimal },
    salinity: { required, decimal },
  },
  components: {
    PondMenu,
    TitlePond,
  },
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
    isCompleted: false,
    page: 1,
    pages: 1,
    samples_list: [],
    sample_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), // Add Sample
    status: 0,
    assigned: '',
    week: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    size: null,
    temperature: null,
    oxygen: null,
    salinity: null,
    note: '',
    totalHarvested: 0,
    snackbar: false,
    snackbar_text: '',
    menu2: false,
    menu3: false,
  }),
  created() {
    this.$debug("created", this.pond)
    if ( !this.pond ) this.$router.push('/ponds')
    this.listSamples();
  },
  mounted() {
    if (this.pond) this.isCompleted = this.completed
  },
  methods: {
    // Get week date from WOC
    getWeekDate(d) {
      return moment(d).format("DD/MM/YYYY (ddd)");
    },

    // Error Handling
    fieldErrors(field) {
      this.$debug('fieldErrors', field, this.$v, this.$v[field] )
      if (!this.$v[field].$dirty || !this.$v[field].$error) return [];
      else if (!this.$v[field].decimal) return ["Number is required."];
      else if (!this.$v[field].required) return ["This field is required."];
    },

    // List Samples
    async listSamples() {
      try {
          this.$debug( "API_SERVER", this.API_SERVER, "this.pond", this.pond )
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/resultsnew/?format=json&pond=${this.pond.id}`
          );
          this.$debug("get weekly results lists", response);
          if (response.status === 200 && response.data.results.length) {
            // Calculate total harvested amount
            response.data.results.map(o => {
              try {
                if ( o.harvested ) this.totalHarvested += o.harvested
              } catch(e) {
                return
              }
            })

            this.samples_list = response.data.results;
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },
    
    // Total Pond Biomass
    getBiomass(count, weight) {
        if (weight) return Math.round( (count*weight)/453.5 );
        else return '';
    },

    // Total Pond Biomass (kg)
    getBiomassKilo(count, weight) {
        if (weight) return Math.round( (count*weight)/1000 );
        else return '';
    },

    // List Samples
    handlePageChange(page) {
      this.listSamples(page);
    },

    // Get week Number for the date 
    getWeekNo(v_date_str) {
      const days = moment(v_date_str).diff(moment(this.pond.date), 'days')+1
      this.$debug('getWeekNo', moment(v_date_str), moment(this.pond.date), days, Math.ceil(days / 7))
      return Math.ceil(days / 7);
    },

    // check number
    checkNumber(o) {
      try {
        this.$debug('checkNumber', o);
        const trimmed = o.trim();
        this.$debug('trimmed', trimmed);

        if (trimmed === '') return "";
        else return parseFloat(o.trim());
      } catch (e) {
        Swal.fire(
          `Please Input number!`,
          "",
          "warning"
        );
        return false;
      }
    },

    // change completed
    async partialUpdate(field) {
      try {
        this.$debug('partialUpdate', field)
        var formData = new FormData();

        if (field === 'completed') {
          this.$debug('this.completed', this.completed)
          formData.append("completed", this.completed ? this.completed : '' );
          this.isCompleted = this.completed;
        
        } else if (field === 'harveststarted') {
          this.$debug('this.harveststarted', this.harveststarted, this.pond.harveststarted)
          formData.append("harveststarted", this.harveststarted ? this.harveststarted : '' );
        
        } else if (field === 'harvested') {
          const newNum = this.checkNumber(this.harvested)
          
          this.$debug('newNum', newNum, 'this.harvested', this.harvested)
          formData.append("harvested", newNum );
          if (newNum === false) {
            return;
          } else {
            this.harvested = newNum;
            formData.append("harvested", newNum );         
          }
        
        } else if (field === 'avgweight') {
          const newNum = this.checkNumber(this.pond.avgweight)
          if (newNum === false) {
            return;
          } else {
              this.avgweight = newNum;
              formData.append("avgweight", newNum );
          }
        
        } else {
          return
        }

        this.$debug("partialUpdate_formData", formData)

        await tokenAxios(this.user_info.access_token).patch(
          process.env.VUE_APP_API_SERVER + `/farm/pond/${this.pond.id}/`,
          formData
        );

        this.$root.vtoast.show({message: `Week ${this.pond.pond_id} for ${this.getPond} is updated`})

        // set Submitted
        this.status = 0;
        this.listSamples();

      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },

    // Submit samples to server
    async updateResults() {
      const update = await Swal.fire({
        title: 'Do you want to update new result?',
        showCancelButton: true,
        confirmButtonText: 'Update',
      })
      if (!update.isConfirmed) return;

      try {
        var formData = new FormData();

        var result = await tokenAxios(this.user_info.access_token).patch(
          process.env.VUE_APP_API_SERVER + `/farm/resultsnew/${this.pond.id}/`,
          formData
        );
        this.$debug('updateResults result', result);

        if(result.data != "error") {
          Swal.fire(
            `Result for ${this.getPond} is updated !!!`,
            "",
            "success"
          );

          // For pond menu, update results_count
          var newdata = {
            ...this.pond,
            'results_count': this.pond.results_count + parseInt(result.data),
            'results_new_count': 0,
          }
          this.$debug("newdata", newdata)
          this.$store.commit("pond", newdata);
          
          // Move to Result
          this.$router.push("/farmersamples");
        }
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },
  }
}
</script>
<style scoped>

.minnowtech {
  background-color: #eee
}
.weekno {
  color: #07124f;
  font-weight: bold;
  margin-right: 20px;
}
.border-left {
  border-left: 1px solid #e2eaf1
}
.border-right {
  border-right: 1px solid #e2eaf1
}
.scroll {
  overflow: auto;
}
.scroll .v-data-table {
  width: 100%;
  max-width: none;
  position: relative;
}
.scroll th {
  position: sticky;
  top: 0px;
  z-index: 2;
}
.woc-date {
  width: 150px;
  font-size: 12px;
}
.shrimp {
  text-align: center;
  border-radius: 30px;
  /* background-color: #fcab31; */
  /* color: white; */
  border: 1px solid #1976d2;
  color: #1976d2;
  width: 93px;
  padding: 4px 10px;
  margin: auto;
  font-size: 15px;
  font-weight: bold;
}
.biomass {
  /* background-color: #f26835; */
}
.pondinput {
    line-height: 15px;
    color: rgba(0, 0, 0, 0.7);
    max-width: 120px;
}
.pondcarrot {
  margin: 0 2px 0 10px;
}
.pondcarrot:before{
  content: "💠"
}
.harveststarted {
  margin: 20px;
  padding: 10px 20px;
}
.updateresult {
  color: white;
  font-weight: bold;
  font-size: 18px;
}
</style>
